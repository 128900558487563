import React from "react";
import './Card.css';

export default function Card(props) {
  const {title, username, content, link, image} = props.post;

  // A post's image can be an image or gif link
  const Media = () => (image ? <img src={image} className="image" alt={title} /> : null);

  return (
    <div className="card">
      <h1 className="title">{title}</h1>
      <div className="media">
        <Media />
      </div>
      <div className="bottom-row">
        <p className="text">
          <em className="username">@{username}</em>
          &nbsp;
          {content}
        </p>
      </div>
      {link && (
        <a href={link} rel="noreferrer" className="small-text" target="_blank">
          {link}
        </a>
      )}
    </div>
  );
};
