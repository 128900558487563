// We're just going to keep this simple, no reason to over-complicate

import { useEffect, useState } from "react";
import Card from "./components/Card";

const WORKER_URL = "https://workers.grantmatejka.workers.dev";

function App() {
  const [posts, setPosts] = useState([]);

  // We could also just use useReducer
  const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");

  const createPost = e => {
    e.preventDefault();
    fetch(WORKER_URL + "/posts", {
      method: "POST",
      body: JSON.stringify({
        title: title,
        username: username,
        image: image,
        content: content,
        link: link
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        setTitle("");
        setImage("");
        setContent("");
        setUsername("");
        setLink("");
        getPosts();
      })
      .catch((e) => console.error(e));
  };

  const getPosts = () => {
    fetch(WORKER_URL + "/posts", {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((ps) => setPosts(ps))
      .catch((e) => console.error(e));
  };

  useEffect(getPosts, []);

  return (
    <div className="body">
      <div className="container">
        <div className="form-col">
          <h2>Make New Post</h2>
          <form onSubmit={createPost} className="post-form">
            <div className="form-entry">
              <label htmlFor="title" className="form-label">
                Title*
              </label>
              <input
                id="title"
                name="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="form-entry">
              <label htmlFor="image" className="form-label">
                Image
              </label>
              <input
                id="image"
                name="image"
                type="url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>

            <div className="form-entry">
              <label htmlFor="content" className="form-label">
                Content*
              </label>
              <input
                id="content"
                name="content"
                type="text"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className="form-entry">
              <label htmlFor="username" className="form-label">
                Username*
              </label>
              <input
                id="username"
                name="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-entry">
              <label htmlFor="link" className="form-label">
                Link
              </label>
              <input
                id="link"
                name="link"
                type="url"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="caveat">
              <small>* Required</small>
            </div>
            <div>
              <button type="submit" className="form-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="row">
          {posts.length ? (
            posts.map((p, i) => <Card post={p} key={i} />)
          ) : (
            <p>No Posts</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
